@@include("./components/hover-link-canvas.js")
@@include("./components/display-time.js")
@@include("./components/home-hero-canvas.js")

$('.hero-bg').addClass('display-bg');
displayTime();
initCanvas();

function updateTocItemHeight() {
  var tocContentHeight = 0;
  $('.toc-content').css('height',$('.toc-verticaltext').outerHeight() - ($('#home-canvas').height()*0.75)+'px');
  $('.toc-content').css('margin-top',($('#home-canvas').height()/2)+'px');
  $('.toc-item-ctn').each(function(){tocContentHeight += $(this).height()} )
  $('.toc-item-ctn').css('padding-bottom',(($('.toc-verticaltext').outerHeight() - tocContentHeight - $('#home-canvas').height()) / $('.toc-item-ctn').length)+'px');
  $('.toc-content-ctn').css('padding-top',($(window).height()/2)+'px');
  $('.toc-verticaltext').css('top',($(window).height()/2)+'px');
  $('.toc-verticaltext').css('margin-top',-($('#home-canvas').height()/4)+'px');
  $('.toc-bg-ctn').css('height',$(window).innerHeight()+'px');
}

(function() {
  var windowWidth = window.innerWidth;
  var windowHeight = window.innerHeight;
  updateTocItemHeight();

  $(window).resize($.debounce(100,false,function(){
    // exclude browser address bar resize
    if(windowWidth != window.innerWidth || Math.abs(windowHeight - window.innerHeight) > 95) {
      windowWidth = window.innerWidth;
      windowHeight = window.innerHeight;
      updateTocItemHeight();
    }
  }));

  var windowsPC = new RegExp('Windows NT');
  var chromeOS = new RegExp('CrOS');
  if ( $('html').hasClass('no-touchevents') || windowsPC.test(navigator.userAgent) || chromeOS.test(navigator.userAgent) ) {
    $('html').addClass('init-scroll')
    hoverLinkCanvas('home-canvas');
    var controller;
    setScrollTrigger()

    $(window).resize($.debounce(100,false,function(){
      setScrollTrigger()
    }));

    function setScrollTrigger() {
      if(controller != undefined) {controller=controller.destroy(true)}
      controller = new ScrollMagic.Controller();
      new ScrollMagic.Scene({
        triggerElement: '.toc-bg-ctn',
        triggerHook: 'onLeave',
        duration: $('.toc-content-ctn').outerHeight() - $(window).height()
      })
      .setPin('.toc-bg-ctn')
      .addTo(controller);

      $('.manifesto p').each(function(){
        new ScrollMagic.Scene({
          triggerElement: this,
          triggerHook: 'onEnter',
          offset: 100
        })
        .setClassToggle(this,'trigger-active')
        .addTo(controller);
      })

      $('.toc-item-ctn').each(function(){
        var $this = $(this);
        new ScrollMagic.Scene({
          triggerElement: this,
          duration: $this.outerHeight()
        })
        .on("enter leave", function (e) {
          e.type == "enter" ? $this.find('[data-hover-src]').trigger('mouseover') : $this.find('[data-hover-src]').trigger('mouseout')
        })
        .addTo(controller);
      });
    }

  }
})();
